import React from "react";
import '../styles/Map.css'

function Map() {
    return (
        <div class="main">
            <h1>Map</h1>
        </div>
        
    );
}

export default Map;