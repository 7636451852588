import React from "react";
import '../styles/Rosters.css'
import { RosterList } from '../helpers/RosterList'
import { Link } from "react-router-dom"

function Rosters() {
    return (
        <div className="main">
            <h1>Rosters</h1>
            <ul>
            {RosterList.map((roster, index) => {
                    return (
                        <li>
                            <Link to={roster.link} target="_blank" style={{textDecoration: 'none'}}><b>{roster.rostername}</b></Link>
                            <p>{roster.playername} (<i>{roster.rostertype}</i>)</p>
                        </li>
                    );
                })}
            </ul>
        </div>
        
    );
}

export default Rosters;