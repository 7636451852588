import React from "react";
import '../styles/DesGen.css'
import { Link } from "react-router-dom"

function DesGen() {
    return (
        <div class="main">
            <h1>DesGen</h1>
            <p>You can use a Steam Workshop mod to generate easy to read descriptions for your gangers. This can be done as follows:</p>
            <ol>
                <li>Subscribe to the <Link to="https://steamcommunity.com/sharedfiles/filedetails/?id=3052998210" target="_blank" style={{textDecoration: 'none'}}><b>'Ganger Description Generator'</b></Link> mod on the Steam Workshop.</li>
                <li>Open Tabletop Simulator and start a singleplayer game. Load the Ganger Description Generator mod.</li>
                <li>Load in the models that you are using for your gangers.</li>
                <li>Go to your gang page on Yaktribe.games. Select JSON on the right, below PRINT FUNCTIONS, and copy the URL of the resultant webpage.</li>
                <li>Paste the URL into the text entry box in the mod, and click submit. This will create a series of buttons, one per ganger.</li>
                <li>One by one, select each ganger (drag a box around it) and then left click the appropriate button. This will set the ganger description.</li>
                <li>Once all of your descriptions have been set, save the set of gangers ready to be loaded into your battles.</li>
            </ol>
        </div>
        
    );
}

export default DesGen;