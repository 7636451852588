import React from "react";
import '../styles/Results.css'

function Results() {
    return (
        <div class="main">
            <h1>Results</h1>
        </div>
        
    );
}

export default Results;