import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Map from './pages/Map';
import Rosters from './pages/Rosters';
import Results from './pages/Results';
import DesGen from './pages/DesGen';

import Navbar from "./components/Navbar"
import './App.css'


function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Map />} />
          <Route path="/Rosters" element={<Rosters />} />
          <Route path="/Results" element={<Results />} />
          <Route path="/DesGen" element={<DesGen />} />
        </Routes>
      </Router>
    </div>
  );  
}

export default App;