export const RosterList = [

    {
        playername: "Elyod",
        rostertype: "Genestealer Cults",
        rostername: "Three Arms No Hands",
        link: "https://yaktribe.games/underhive/gang/three_arms_no_hands.440234/",
    },

    {
        playername: "Dimmy",
        rostertype: "Orlocks",
        rostername: "Cursed Cowboys III",
        link: "https://yaktribe.games/underhive/gang/cursed_cowboys_iii.439091/",
    },

    {
        playername: "Hankok",
        rostertype: "Corpse Grinders",
        rostername: "KOK GRINDERS",
        link: "https://yaktribe.games/underhive/gang/kok_grinders.438011/",
    },

    {
        playername: "Gdaynick",
        rostertype: "House Cawdor",
        rostername: "Not Evil Percy",
        link: "https://yaktribe.games/underhive/gang/not_evil_percy.439537/",
    },

    {
        playername: "Rick",
        rostertype: "House Escher",
        rostername: "No Man's Land",
        link: "https://yaktribe.games/underhive/gang/no_man’s_land.442465/",
    },

    {
        playername: "Mayhemzz",
        rostertype: "",
        rostername: "",
        link: "",
    },

    {
        playername: "JackBull",
        rostertype: "Squat Prospectors",
        rostername: "Tech SUS Pipe Layers",
        link: "https://yaktribe.games/underhive/gang/tech_sus_pipe_layers.439444/",
    },
]